/* eslint-disable no-console */
import React, { useContext } from 'react';
import {
  Flex,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import firebase from '../config/firebase';
import { AuthContext } from '../config/context';

const Answer = ({ id: aid, message, plates, time }) => {
  const { id: qid } = useParams();
  const db = firebase.firestore();
  const toast = useToast();

  const { user } = useContext(AuthContext);

  function deleteAnswer() {
    db.collection('questions')
      .doc(qid)
      .collection('answers')
      .doc(aid)
      .delete()
      .then(() => {
        toast({
          title: `Odpowiedź została usunięta`,
          status: 'success',
          position: 'top',
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: `Wystąpił błąd podczas usuwania odpowiedzi`,
          status: `error`,
          position: 'top',
          isClosable: true,
        });
        console.log(error);
      });
  }

  return (
    <Flex flexDir="column">
      <Box
        w="100%"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="lg"
        shadow="sm"
        overflow="hidden"
        mb={4}
        bg={'gray.100'}
      >
        <Box px={6} py={4}>
          <Box display="flex" mt="2" alignItems="center">
            <Box
              color="gray.700"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="sm"
            >
              {plates}
            </Box>
            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="sm"
              textTransform="uppercase"
              ml="2"
            >
              {format(time, 'dd-MM-yyyy HH:mm')}
            </Box>
            <Box marginLeft="auto">
              {user && (
                <Menu>
                  <MenuButton
                    bgColor="gray.300"
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    Akcje
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => deleteAnswer(aid)}>
                      Usuń odpowiedź{' '}
                    </MenuItem>
                    <MenuItem>Edytuj odpowiedź</MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Box>
          </Box>

          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          />

          <Box py={4}>{message}</Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default Answer;
