import React from 'react';
import { Box, Heading, Flex, Container, Divider } from '@chakra-ui/react';
import QuestionForm from '../components/QuestionForm';
import Header from '../components/Header';

const AskMechanic = () => (
  <Box maxW="100vw">
    <Header />
    <Container maxW={'4xl'}>
      <Flex flexWrap="wrap" flexDir="row">
        <Flex p={3} px={4} flex="10" m={2}>
          <Box
            minW="xs"
            width="100%"
            px={6}
            py={4}
            border="1px solid"
            borderColor="gray.100"
            rounded="xl"
            shadow="md"
          >
            <Heading size="lg" py="2" display="flex" justifyContent="center">
              Zadaj pytanie
            </Heading>
            <Divider m={2} />
            <QuestionForm />
          </Box>
        </Flex>
        {/* <Flex p={3} px={4} flex="5" flexGrow m={2}>
          <Box
            width="100%"
            minW="xs"
            px={6}
            py={4}
            border="1px solid"
            borderColor="gray.100"
            rounded="xl"
            shadow="md"
          >
            <Heading size="lg" py="2">
              Baza pytań
            </Heading>
            <Divider my={2} />
            <Box py={4}>
              {carBrandList.map(car => (
                <Link exact to={`q/${formatToURL(car)}`}>
                  <Badge
                    m={2}
                    borderRadius="xl"
                    px={3}
                    py={2}
                    colorScheme="red"
                    cursor="pointer"
                  >
                    {car}
                  </Badge>
                </Link>
              ))}
            </Box>
          </Box>
        </Flex> */}
      </Flex>
    </Container>
  </Box>
);

export default AskMechanic;
