/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import {
  Flex,
  Heading,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  FormErrorMessage,
  FormControl,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { AuthContext } from '../config/context';

import firebase from '../config/firebase';
import Answer from './Answer';

const SingleQuestion = () => {
  const [question, setQuestion] = useState();
  const [answers, setAnswers] = useState();
  const [ansCount, setAnsCount] = useState(0);
  const { id: qid } = useParams();
  const db = firebase.firestore();
  const history = useHistory();
  const toast = useToast();
  const { user } = useContext(AuthContext);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(
    // get single question data
    () =>
      db
        .collection('questions')
        .doc(qid)
        .get()
        .then(snapshot => setQuestion(snapshot.data())),
    []
  );

  useEffect(
    // get all answers
    () =>
      db
        .collection('questions')
        .doc(qid)
        .collection('answers')
        .orderBy('createdAt', 'asc')
        .limit(10)
        .onSnapshot(snapshot => {
          const arr = [];
          snapshot.forEach(doc => arr.push({ id: doc.id, ...doc.data() }));
          setAnswers(arr);
          setAnsCount(arr.length);
        }),
    []
  );

  function deleteQuestion() {
    db.collection('questions')
      .doc(qid)
      .delete()
      .then(() => {
        toast({
          title: `Pytanie zostało usunięte`,
          status: 'success',
          position: 'top',
          isClosable: true,
        });

        history.goBack();
      })
      .catch(error => {
        toast({
          title: `Wystąpił błąd podczas usuwania pytania`,
          status: `error`,
          position: 'top',
          isClosable: true,
        });
        console.log(error);
      });
  }
  // onsubmit answer
  function onSubmit(values, e) {
    db.collection('questions')
      .doc(qid)
      .collection('answers')
      .doc(uuidv4())
      .set({
        name: values.name,
        message: values.message,
        createdAt: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        e.target.reset();
        toast({
          title: `Odpowiedź została dodana do bazy`,
          status: 'success',
          position: 'top',
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: `Wystąpił błąd podczas dodawania odpowiedzi`,
          status: 'error',
          position: 'top',
          isClosable: true,
        });
        console.log(error);
      });
  }

  const ActionMenu = () => (
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
        Akcje
      </MenuButton>
      <MenuList>
        <MenuItem onClick={deleteQuestion}>Usuń pytanie</MenuItem>
      </MenuList>
    </Menu>
  );

  return (
    <>
      <Flex flexDir="column" mb={4} minW="100%">
        <Box
          minW="100%"
          border="1px solid"
          borderColor="gray.100"
          borderRadius="lg"
          shadow="sm"
          overflow="hidden"
          mb={[4, 4, 4]}
        >
          <Box p={[3, 6, 6]}>
            <Flex mt="2" alignItems="center">
              <Box
                color="gray.500"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="sm"
                textTransform="uppercase"
              >
                {question?.createdAt &&
                  format(question?.createdAt.toDate(), 'dd-MM-yyyy HH:mm')}
              </Box>
              <Box marginLeft="auto">{user && <ActionMenu />}</Box>
            </Flex>

            <Box
              mt="1"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
            >
              <Heading size="lg" py="2">
                {question?.title}
              </Heading>
              <Flex flexDir="column" py={4}>
                <span>
                  <span fontSize="sm">Marka:</span> {question?.brand}
                </span>
                <span>
                  <span fontSize="sm" color="gray.600">
                    Model:{' '}
                  </span>
                  {question?.model}
                </span>
                <span>
                  <span fontSize="sm" color="gray.600">
                    Rocznik:{' '}
                  </span>
                  {question?.year}
                </span>
                <span>
                  <span fontSize="sm" color="gray.600">
                    Silnik:{' '}
                  </span>
                  {question?.engine}
                </span>
                {user && (
                  <>
                    <Text mt={4} mb={4}>
                      Dodatkowe informacje:
                    </Text>
                    <span>
                      <span fontSize="sm" color="gray.600">
                        Nr rejestracyjny:{' '}
                      </span>
                      {question?.plates}
                    </span>
                    <span>
                      <span fontSize="sm" color="gray.600">
                        Email:{' '}
                      </span>
                      {question?.email}
                    </span>
                    <span>
                      <span fontSize="sm" color="gray.600">
                        Przebieg:{' '}
                      </span>
                      {question?.mileage} km
                    </span>
                    <span>
                      <span fontSize="sm" color="gray.600">
                        VIN:{' '}
                      </span>
                      {question?.vin}
                    </span>
                  </>
                )}
              </Flex>
            </Box>

            <Box py={4}>{question?.description}</Box>

            <Box as="span" py={1} color="gray.600" fontSize="sm">
              odpowiedzi ({ansCount})
            </Box>
          </Box>
        </Box>
      </Flex>
      <Box>
        {user && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors.name} mb="4">
              <Input
                id="name"
                w="30%"
                placeholder="Imię"
                {...register('name', {
                  required: 'To pole jest wymagane',
                  minLength: {
                    value: 4,
                    message: 'Minimalna długość powinna wynosić 4',
                  },
                })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.message} mb="4">
              <Textarea
                id="message"
                height="200"
                placeholder="Odpowiedź mechanika"
                {...register('message', {
                  required: 'To pole jest wymagane',
                  minLength: {
                    value: 4,
                    message: 'Minimalna długość powinna wynosić 4',
                  },
                })}
              />
              <FormErrorMessage>
                {errors.message && errors.message.message}
              </FormErrorMessage>
            </FormControl>
            <Flex>
              <Button
                color="white"
                bg={'#a00012'}
                _hover={{
                  bg: 'red.800',
                }}
                isLoading={isSubmitting}
                type="submit"
                mb={8}
              >
                Wyślij odpowiedź
              </Button>
            </Flex>
          </form>
        )}
      </Box>
      {answers?.map(ans => (
        <Answer
          id={ans.id}
          key={ans.id}
          message={ans.message}
          author={ans.name}
          time={ans.createdAt.toDate()}
        />
      ))}
    </>
  );
};

export default SingleQuestion;
