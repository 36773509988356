import React from 'react';
import { Box, Heading, Flex, Container, Divider } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import QuestionList from '../components/QuestionList';
import formatURLtoString from '../utils/formatURLtoString';
import BackButton from '../components/BackButton';

const Questions = () => {
  const { brand } = useParams();

  return (
    <Box>
      <Header />
      <Container maxW={'7xl'} minH="100vh">
        <Flex p={[2, 3, 3]} m={[0, 4, 4]}>
          <Box minW="100%" m={[0, 2, 2]}>
            <BackButton link="/" text="Powrót do strony głównej" />
            <Heading size="lg" py="2">
              {brand
                ? `Lista pytań dla ${formatURLtoString(brand)}`
                : `Lista wszystkich pytań`}
            </Heading>
            <Divider my={2} />
            <QuestionList query={brand} />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Questions;
