import React from 'react';
import { Box } from '@chakra-ui/react';
import Navbar from './Navbar';
import Footer from './Footer';

export default function Layout({ children }) {
  return (
    <Box pt="92px">
      <Navbar />
      {children}
      <Footer />
    </Box>
  );
}
