import React from 'react';
import { Box, Flex, Container } from '@chakra-ui/react';
import Header from '../components/Header';
import SingleQuestion from '../components/SingleQuestion';
import BackButton from '../components/BackButton';

const QuestionsDetail = () => (
  <Box>
    <Header />
    <Container maxW={'7xl'}>
      <Flex minH="100vh" p={[0, 3, 3]} m={[2, 4, 4]} flexWrap="wrap">
        <Box minW="100%" m="2">
          <BackButton link="/q" text="Powrót do listy pytań" />
          <SingleQuestion />
        </Box>
      </Flex>
    </Container>
  </Box>
);

export default QuestionsDetail;
