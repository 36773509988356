import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ChakraProvider, theme } from '@chakra-ui/react';
import Layout from './components/Layout';
import Home from './pages/Home';
import AskMechanic from './pages/AskMechanic';
import Questions from './pages/Questions';
import QuestionsDetail from './pages/QuestionDetail';
import Login from './components/Login';
import { AuthProvider } from './config/context';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';
import Success from './pages/Success';

function App() {
  return (
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <Router>
          <Layout>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/ask">
                <AskMechanic />
              </Route>
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route exact path="/about">
                <AboutUs />
              </Route>
              <Route exact path="/thankyou">
                <Success />
              </Route>
              <Route exact path="/q">
                <Questions />
              </Route>
              <Route exact path="/q/:brand">
                <Questions />
              </Route>
              <Route exact path="/q/:brand/:id">
                <QuestionsDetail />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
            </Switch>
          </Layout>
        </Router>
      </ChakraProvider>
    </AuthProvider>
  );
}

export default App;
