import React from 'react';
import { Container, Flex, Text } from '@chakra-ui/react';

const AboutUs = () => (
  <Flex minH="calc(100vh - 160px)">
    <Container maxW={'7xl'} mt="50px" mb="50px">
      {/* <SimpleGrid columns={{ base: 2, md: 2 }} spacing={0}> */}
      {/* <Flex bg="brand.400" alignItems="center">
          <Image
            src="https://images.pexels.com/photos/2244746/pexels-photo-2244746.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            alt="3 women looking at a laptop"
            fit="cover"
            w="full"
            h="lg"
            bg="brand.100"
            loading="lazy"
            opacity={0.8}
            rounded="2xl"
          />
        </Flex> */}
      <Flex
        direction="column"
        alignItems="start"
        justifyContent="center"
        px={{ base: 8, md: 8, lg: 20 }}
        py={8}
        zIndex={3}
      >
        <Text
          mt={2}
          fontSize={{ base: '3xl', sm: '4xl' }}
          lineHeight="10"
          fontWeight="extrabold"
          letterSpacing="tight"
          color={'gray.900'}
          py={2}
        >
          O nas
        </Text>
        <Text color={'gray.600'}>
          Pracując w branży motoryzacyjnej od wielu lat byliśmy źródłem
          rzetelnej wiedzy dostępnej dla naszych znajomych, przyjaciół i
          rodziny. Nie każdy ma podobne możliwości, a ludzie nie muszą znać się
          na wszystkim - dlatego dostrzegamy wielkie zapotrzebowanie na wiedzę i
          fachowe doradztwo w zakresie serwisu i naprawy samochodów osobowych.
        </Text>
      </Flex>
      <Flex
        direction="column"
        alignItems="start"
        justifyContent="center"
        px={{ base: 8, md: 8, lg: 20 }}
        py={8}
        zIndex={3}
      >
        <Text
          mt={2}
          fontSize={{ base: '3xl', sm: '4xl' }}
          lineHeight="10"
          fontWeight="extrabold"
          letterSpacing="tight"
          color={'gray.900'}
          py={2}
        >
          Kim jesteśmy?
        </Text>
        <Text color={'gray.600'}>
          Jesteśmy grupą specjalistów, pasjonatów i przede wszystkim ludźmi,
          którzy cenią jakość i bezpieczeństwo. Mamy lata doświadczeń w branży
          motoryzacyjnej oraz wzajemnie weryfikujemy swoją wiedzę podnosząc tym
          samym poprzeczkę. Chcemy pomóc wam we wstępnej diagnozie problemu oraz
          wskazać drogę, która mamy nadzieję pomoże rozwiązać wasze problemy z
          autem. Za darmo.
        </Text>
      </Flex>
      <Flex
        direction="column"
        alignItems="start"
        justifyContent="center"
        px={{ base: 8, md: 8, lg: 20 }}
        py={8}
        zIndex={3}
      >
        <Text
          mt={2}
          fontSize={{ base: '3xl', sm: '4xl' }}
          lineHeight="10"
          fontWeight="extrabold"
          letterSpacing="tight"
          color={'gray.900'}
          py={2}
        >
          Dlaczego?
        </Text>
        <Text color={'gray.600'}>
          Dla zwiększenia świadomości kierowców.
          <br />
          Dla bezpieczeństwa i komfortu wszystkich użytkowników ruchu.
          <br /> Dla satysfakcji z pomocy i chęci dzielenia się swoją wiedzą.
          <br />
          Na pohybel nieuczciwym praktykom i błędnym diagnozom!
        </Text>
      </Flex>
      {/* </SimpleGrid> */}
    </Container>
  </Flex>
);

export default AboutUs;
