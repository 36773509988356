import React, { useState } from 'react';
import { Flex, Box, Text, Radio, Icon } from '@chakra-ui/react';
import { HiMenuAlt1 } from 'react-icons/hi';
import { Link } from 'react-scroll';
import '../styles/FloatNavbar.css';

const NAV_ITEMS = [
  {
    id: 1,
    label: 'Zapytaj mechanika',
    href: 'zapytajmechanika',
  },
  {
    id: 2,
    label: 'Jak to działa?',
    href: 'jaktodziala',
  },
  {
    id: 3,
    label: 'O projekcie',
    href: 'oprojekcie',
  },
];

function FloatNavbar() {
  const [open, setOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState('');

  return (
    <Box
      display="block"
      rounded="xl"
      mr={[2, 4, 4]}
      border="1px solid"
      borderColor="gray.100"
      shadow="md"
      p={[4, 4, 4]}
      bg="white"
      pos="fixed"
      right="0"
      top={['12%', '20%', '20%']}
      zIndex="100"
    >
      <Flex flexDir="column" alignItems="flex-end" pl={open ? 8 : 0}>
        <Flex>
          <Icon
            as={HiMenuAlt1}
            w={6}
            h={6}
            my={2}
            cursor="pointer"
            onClick={() => setOpen(!open)}
          />
        </Flex>
        {NAV_ITEMS.map(item => (
          <Box mb={1}>
            <Link
              to={item.href}
              offset={-100}
              smooth
              spy
              key={item.href}
              activeClass="active"
              onSetActive={e => setCurrentSection(e)}
              delay={0}
            >
              <Flex>
                <Text
                  pr={4}
                  _hover={{ textDecor: 'underline', cursor: 'pointer' }}
                  display={open ? 'block' : 'none'}
                >
                  {item.label}
                </Text>

                <Radio
                  isChecked={item.href === currentSection}
                  m={1}
                  cursor="pointer"
                  colorScheme="red"
                />
              </Flex>
            </Link>
          </Box>
        ))}
      </Flex>
    </Box>
  );
}

export default FloatNavbar;
