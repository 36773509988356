import React, { useState, useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import Card from './Card';
import firebase from '../config/firebase';

const QuestionList = props => {
  const [questions, setQuestions] = useState();
  const { query } = props;
  useEffect(() => {
    const db = firebase.firestore();
    if (query === undefined) {
      // load all the questions
      return db
        .collection('questions')
        .orderBy('createdAt', 'desc')
        .limit(50)
        .onSnapshot(snapshot => {
          const arr = [];
          snapshot.forEach(doc => arr.push({ id: doc.id, ...doc.data() }));
          setQuestions(arr);
        });
    }
    return db
      .collection('questions')
      .where('brand', '==', query)
      .get()
      .then(querySnapshot => {
        const arr = [];
        querySnapshot.forEach(doc => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        // TODO: fix orderBy issue
        setQuestions(arr);
      });
  }, []);

  return (
    <Flex flexDir="column">
      {questions?.map(
        ({ id, title, description, brand, model, year, author, createdAt }) => (
          <Card
            key={id}
            id={id}
            title={title}
            description={description}
            brand={brand}
            model={model}
            year={year}
            author={author}
            createdAt={createdAt}
          />
        )
      )}
      {questions?.length < 1 && (
        <Text py={4}>Nie znaleźliśmy pytań w tej kategorii.</Text>
      )}
    </Flex>
  );
};

export default QuestionList;
