import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Container,
  Button,
  Flex,
} from '@chakra-ui/react';
import { FcAddressBook, FcAssistant, FcSearch } from 'react-icons/fc';
import { FiExternalLink } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';

const StepByStep = ({ title, text, icon }) => (
  <Stack borderRadius="2xl" p={4} shadow="base" bg="white">
    <Flex
      w={16}
      h={16}
      align={'center'}
      justify={'center'}
      color={'white'}
      rounded={'full'}
      bg={'gray.100'}
      mb={1}
      alignSelf="center"
    >
      {icon}
    </Flex>
    <Text fontWeight={600}>{title}</Text>
    <Text color={'gray.600'}>{text}</Text>
  </Stack>
);

export default function SimpleThreeColumns() {
  return (
    <Container maxW={'100vw'} p={0}>
      <Box>
        <Flex
          flexDir="column"
          justifyContent="space-around"
          bg="black"
          w={'100%'}
          minH={'100%'}
        >
          <Box
            maxW={'7xl'}
            p={8}
            display="flex"
            justifyContent="center"
            flexDir="column"
            margin="0 auto"
          >
            <Box flex="3" width="100%" padding="10" textAlign="center">
              <Text
                mt={2}
                fontSize={['3xl', '5xl', '5xl']}
                lineHeight="8"
                fontWeight="extrabold"
                letterSpacing="tight"
                color={'white'}
              >
                Jak to działa?
              </Text>
              <Text
                mt={4}
                maxW="3xl"
                fontSize={['xl', '2xl', '2xl']}
                mx={{ lg: 'auto' }}
                color={'gray.100'}
              >
                Dowiedz się jak bezpłatnie uzyskać profesjonalną odpowiedź na
                twoje pytanie związane z mechaniką samochodową.
              </Text>
            </Box>
            <Box p={[1, 8, 8]} flex="3">
              <SimpleGrid
                columns={{ base: 1, md: 3 }}
                spacing={6}
                fontSize="lg"
              >
                <StepByStep
                  icon={<Icon as={FcSearch} w={10} h={10} />}
                  title={'1. Zadaj pytanie związane z mechaniką samochodową'}
                  text={
                    'Opisz dokładnie swój problem. Pamiętaj aby podać w formularzu wszystkie niezbędne informacje.'
                  }
                />
                <StepByStep
                  icon={<Icon as={FcAssistant} w={10} h={10} />}
                  title={'2. Nasi specjaliści przygotują dla Ciebie odpowiedź'}
                  text={
                    'Twoje pytanie zostało już dodane do bazy. Teraz musimy poczekać na odpowiedź.'
                  }
                />
                <StepByStep
                  icon={<Icon as={FcAddressBook} w={10} h={10} />}
                  title={'3. Powiadomienie email'}
                  text={
                    'Gdy tylko pojawi się odpowiedź na twoje pytanie, zostaniesz o tym fakcie powiadomiony na adres email podany w formularzu.'
                  }
                />
              </SimpleGrid>
              <Box display="flex" justifyContent="center" rounded="md" mt={10}>
                <NavLink to="/ask">
                  <Button
                    shadow="md"
                    size={'md'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'red'}
                    bg={'#a00012'}
                    _hover={{ bg: 'red.800' }}
                  >
                    Zadaj pytanie
                    <Icon as={FiExternalLink} ml={2} />
                  </Button>
                </NavLink>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
}
