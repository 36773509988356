import React, { useContext, useState } from 'react';
import firebase from 'firebase';
import Reaptcha from 'reaptcha';
import {
  Heading,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Box,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../config/context';

function Login() {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const toast = useToast();
  const { user } = useContext(AuthContext);

  const [verified, setVerified] = useState(false);
  const sitekey = process.env.REACT_APP_RECAPTCHA_SITEKEY;

  const onVerify = recaptchaResponse => {
    if (recaptchaResponse) {
      setVerified(true);
    }
  };

  function onSubmit(values) {
    if (!verified) {
      return;
    }
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(() => {
        toast({
          title: `Pomyślnie zalogowano`,
          status: 'success',
          position: 'top',
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: `Błąd logowania: ${error.message}`,
          status: 'error',
          position: 'top',
          isClosable: true,
        });
      });
  }

  return (
    <Box>
      {user && (
        <Redirect
          to={{
            pathname: '/ask',
          }}
        />
      )}

      <Flex
        flexDir="column"
        alignContent="center"
        textAlign="center"
        height="100vh"
        margin="0 auto"
        pt={100}
        pr={4}
        pl={4}
        maxWidth={400}
      >
        <Heading mb={16}>Logowanie</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.email} mb="4">
            <Flex>
              <Input
                id="email"
                placeholder="Email"
                {...register('email', {
                  required: 'To pole jest wymagane',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'To nie jest prawidłowy email',
                  },
                })}
              />
            </Flex>
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.password} mb="4">
            <Flex>
              <Input
                id="password"
                type="password"
                placeholder="Hasło"
                {...register('password')}
              />
            </Flex>
          </FormControl>

          <Reaptcha sitekey={sitekey} onVerify={onVerify} />

          <Button
            mt={4}
            color="white"
            width="full"
            bg={'#a00012'}
            _hover={{
              bg: 'red.800',
            }}
            isLoading={isSubmitting}
            type="submit"
            disabled={!verified}
          >
            Zaloguj
          </Button>
        </form>
      </Flex>
    </Box>
  );
}

export default Login;
