/* eslint-disable no-console */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import Reaptcha from 'reaptcha';
import {
  Flex,
  Link,
  Text,
  Checkbox,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Box,
  Select,
  Button,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { carBrandList } from '../data/cars';
import firebase from '../config/firebase';
import formatToURL from '../utils/formatToURL';
import HelpModal from './HelpModal';

const QuestionForm = () => {
  const history = useHistory();
  const toast = useToast();
  const db = firebase.firestore();

  const [verified, setVerified] = useState(false);
  const sitekey = process.env.REACT_APP_RECAPTCHA_SITEKEY;

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const onVerify = recaptchaResponse => {
    if (recaptchaResponse) {
      setVerified(true);
    }
  };

  function onSubmit(values, e) {
    if (!verified) {
      toast({
        title: `Wystąpił błąd podczas dodawania pytania`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
      e.target.reset();
      return;
    }
    db.collection('questions')
      .doc(uuidv4())
      .set({
        title: values.title,
        description: values.description,
        brand: formatToURL(values.brand),
        model: values.model,
        year: values.year,
        vin: values.vin,
        engine: values.engine,
        mileage: values.mileage,
        email: values.email,
        plates: values.plates,
        terms: values.terms,
        createdAt: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        e.target.reset();
        // toast({
        //   title: `Pytanie dodane do bazy`,
        //   status: 'success',
        //   position: 'top',
        //   isClosable: true,
        // });
        history.push('/thankyou');
      })
      .catch(error => {
        toast({
          title: `Wystąpił błąd podczas dodawania pytania`,
          status: 'error',
          position: 'top',
          isClosable: true,
        });
        console.log(error);
      });
  }

  return (
    <Box maxW="100vw">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.title} mb="4">
          <FormLabel htmlFor="title">Tytuł *</FormLabel>
          <Flex mr={12}>
            <Input
              id="title"
              placeholder="Podaj krótki tytuł opisujący problem"
              {...register('title', {
                required: 'To pole jest wymagane',
                minLength: {
                  value: 4,
                  message: 'Minimalna długość powinna wynosić 4',
                },
              })}
            />
          </Flex>
          <FormErrorMessage>
            {errors.title && errors.title.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.description} mb="4">
          <FormLabel htmlFor="description">Opis problemu *</FormLabel>
          <Flex mr={12}>
            <Textarea
              id="description"
              placeholder="Dokładny dłuższy opis twojego problemu"
              {...register('description', {
                required: 'To pole jest wymagane',
                minLength: {
                  value: 4,
                  message: 'Minimalna długość powinna wynosić 10',
                },
              })}
            />
          </Flex>
          <FormErrorMessage>
            {errors.description && errors.description.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.brand} mb="4">
          <FormLabel htmlFor="brand">Marka samochodu *</FormLabel>
          <Flex>
            <Select
              id="brand"
              {...register('brand', {
                required: 'To pole jest wymagane',
                minLength: {
                  value: 2,
                  message: 'Minimalna długość powinna wynosić 2',
                },
              })}
            >
              {carBrandList.map(car => (
                <option key={car} value={car}>
                  {car}
                </option>
              ))}
            </Select>
            <HelpModal
              title="Marka samochodu"
              description="Markę znajdziesz na pierwszej stronie dowodu rejestracyjnego w polu D.1."
              img="https://kalkulator.kalkulator-oc-ac.auto.pl/kalkulator/common_public/assets/images/infotip-images/marka-pojazdu-infotip.png"
            />
          </Flex>
          <FormErrorMessage>
            {errors.brand && errors.brand.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.model} mb="4">
          <FormLabel htmlFor="model">Model samochodu *</FormLabel>
          <Flex>
            <Input
              id="model"
              placeholder="Podaj model swojego samochodu np. golf"
              {...register('model', {
                required: 'To pole jest wymagane',
                minLength: {
                  value: 1,
                  message: 'Minimalna długość powinna wynosić 1',
                },
              })}
            />
            <HelpModal
              title="Model samochodu"
              description="Model znajdziesz na pierwszej stronie dowodu rejestracyjnego w polu D.1."
              img="https://kalkulator.kalkulator-oc-ac.auto.pl/kalkulator/common_public/assets/images/infotip-images/marka-pojazdu-infotip.png"
            />
          </Flex>
          <FormErrorMessage>
            {errors.model && errors.model.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.year} mb="4">
          <FormLabel htmlFor="model">Rocznik samochodu *</FormLabel>
          <Flex>
            <Input
              id="year"
              placeholder="Podaj rocznik swojego samochodu np. 2006"
              {...register('year', {
                required: 'To pole jest wymagane',
                minLength: {
                  value: 1,
                  message: 'Minimalna długość powinna wynosić 1',
                },
              })}
            />
            <HelpModal
              title="Rok produkcji"
              description="Rok produkcji znajdziesz na ostatniej stronie dowodu rejestracyjnego."
              img="https://kalkulator.kalkulator-oc-ac.auto.pl/kalkulator/common_public/assets/images/infotip-images/rok-produkcji-infotip.png"
            />
          </Flex>
          <FormErrorMessage>
            {errors.year && errors.year.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.vin} mb="4">
          <FormLabel htmlFor="model">Numer VIN samochodu *</FormLabel>
          <Flex>
            <Input
              id="vin"
              placeholder="Podaj numer VIN samochodu"
              {...register('vin', {
                required: 'To pole jest wymagane',
                minLength: {
                  value: 17,
                  message: 'Minimalna długość powinna wynosić 17 znaków',
                },
              })}
            />
            <HelpModal
              title="Numer VIN samochodu"
              description="Numer VIN samochodu znajdziesz na ostatniej stronie dowodu rejestracyjnego."
              img="https://kalkulator.kalkulator-oc-ac.auto.pl/kalkulator/common_public/assets/images/infotip-images/rok-produkcji-infotip.png"
            />
          </Flex>
          <HelpModal
            asLink
            title="Dlaczego potrzebujemy numer VIN samochodu?"
            description="Numer VIN jest nam potrzebny do sprawdzenia szczegółów technicznych waszego auta.
            Jest to numer identyfikacyjny pojazdu, który po rozkodowaniu powie mechanikom więcej odnośnie budowy konkretnego modelu samochodu - ułatwi to wykrycie wadliwych komponentów, analizę najczęstszych problemów, podpowie jakie parametry powinny mieć części zamienne stosowane przy serwisie i wiele innych.
            Zdarzają się problemy z rozkodowaniem VIN’u dlatego w formularzu prosimy o rzetelne uzupełnienie pozostałych rubryk.
            "
          />
          <FormErrorMessage>
            {errors.vin && errors.vin.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.engine} mb="4">
          <FormLabel htmlFor="engine">Silnik *</FormLabel>
          <Flex mr={12}>
            <Input
              id="engine"
              placeholder="Podaj model i moc silnika np. 1.9TDI 105KM"
              {...register('engine', {
                required: 'To pole jest wymagane',
                minLength: {
                  value: 4,
                  message: 'Minimalna długość powinna wynosić 4',
                },
              })}
            />
          </Flex>
          <FormErrorMessage>
            {errors.engine && errors.engine.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.mileage} mb="4">
          <FormLabel htmlFor="mileage">Przebieg *</FormLabel>
          <Flex mr={12}>
            <Input
              id="mileage"
              placeholder="Podaj przebieg np. 220000 (km)"
              {...register('mileage', {
                required: 'To pole jest wymagane',
                minLength: {
                  value: 4,
                  message: 'Minimalna długość powinna wynosić 4',
                },
              })}
            />
          </Flex>
          <FormErrorMessage>
            {errors.mileage && errors.mileage.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.email} mb="4">
          <FormLabel htmlFor="email">Twój email *</FormLabel>
          <Flex mr={12}>
            <Input
              id="email"
              placeholder="Podaj email na który dostaniesz powiadomienie o odpowiedzi"
              {...register('email', {
                required: 'To pole jest wymagane',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                },
                minLength: {
                  value: 4,
                  message: 'Minimalna długość powinna wynosić 4',
                },
              })}
            />
          </Flex>
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.plates} mb="4">
          <FormLabel htmlFor="email">Numer rejestracyjny *</FormLabel>
          <Flex>
            <Input
              id="plates"
              placeholder="Podaj numer rejestracyjny pojazdu"
              {...register('plates', {
                required: 'To pole jest wymagane',
                minLength: {
                  value: 5,
                  message: 'Minimalna długość powinna wynosić 5',
                },
              })}
            />
            <HelpModal
              title="Numer rejestacyjny samochodu"
              description="Numer rejestracyjny samochodu jest nam potrzebny do identyfikacji zapytań."
            />
          </Flex>
          <FormErrorMessage>
            {errors.plates && errors.plates.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.terms} mb="4">
          <Flex mr={12}>
            <Checkbox
              id="terms"
              {...register('terms', {
                required: 'Proszę zaakceptować regulamin, aby przejść dalej.',
              })}
            />
            <Text fontSize="sm" fontWeight="500" ml={2} p={2}>
              Oświadczam, że zapoznałem się z{' '}
              <Link target="_blank" href="/regulamin.pdf">
                regulaminem
              </Link>{' '}
              i{' '}
              <Link target="_blank" href="/polityka_prywatnosci.pdf">
                polityką prywatności
              </Link>{' '}
              usług świadczonych przez zapytajmechanika.pl *
            </Text>
          </Flex>
          <FormErrorMessage>
            {errors.terms && errors.terms.message}
          </FormErrorMessage>
        </FormControl>

        <Text fontSize="sm" fontWeight="500" ml={2} p={2} fontStyle="italic">
          * Pola oznaczone gwiazdką są wymagane
        </Text>

        <Reaptcha sitekey={sitekey} onVerify={onVerify} />

        <Button
          mt={4}
          color="white"
          bg={'#a00012'}
          _hover={{
            bg: 'red.800',
          }}
          isLoading={isSubmitting}
          disabled={!verified}
          type="submit"
        >
          Zadaj pytanie
        </Button>
      </form>
    </Box>
  );
};

export default QuestionForm;
