import React from 'react';
import { Text, Flex, Heading } from '@chakra-ui/react';

const Header = () => (
  <Flex
    size="lg"
    minH="xs"
    minW="full"
    bgSize="cover"
    flexDir="column"
    justifyContent="center"
    alignItems="center"
    bgImage="linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.9)) , url('https://images.pexels.com/photos/3807120/pexels-photo-3807120.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260')"
    bgPos="center"
  >
    <Heading color="white" fontSize="4xl">
      Zapytaj mechanika
    </Heading>
    <Text color="white" p="4" textAlign="center">
      Ponad 7000 mechaników odpowiada na pytania związane z doborem części,
      awaryjnością samochodów i ich serwisowaniem.
    </Text>
  </Flex>
);

export default Header;
