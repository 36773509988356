import React from 'react';
import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { Button, Icon } from '@chakra-ui/react';

function BackButton(props) {
  const { link, text } = props;
  const history = useHistory();

  return (
    <Button my={4} onClick={() => history.push(link)}>
      <Icon as={BiArrowBack} mr={4} /> {text}
    </Button>
  );
}

export default BackButton;
