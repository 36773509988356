import React from 'react';
import {Box} from '@chakra-ui/react';
import CookieConsent from "react-cookie-consent";
import CTA from '../components/CTA';
import StepByStep from '../components/StepByStep';
import Hero from '../components/Hero';
import FloatNavbar from '../components/FloatNavbar';

const Home = () => (
    <Box>
        <FloatNavbar/>
        <Box
            name="zapytajmechanika"
            as="section"
            minH="60vh"
            alignContent="center"
            display="flex"
        >
            <Hero/>
        </Box>
        <Box
            name="jaktodziala"
            as="section"
            minH="60vh"
            alignContent="center"
            display="flex"
        >
            <StepByStep/>
        </Box>
        <Box
            name="oprojekcie"
            as="section"
            minH="60vh"
            display="flex"
            alignContent="center"
            justifyContent="center"
        >
            <CTA/>
        </Box>
        <CookieConsent
            location="bottom"
            buttonText="Akceptuj"
            cookieName="myAwesomeCookieName2"
            style={{background: "#000", fontSize: "13px", display: "flex", alignItems: "center", padding: "0 4rem"}}
            buttonStyle={{backgroundColor: "#a00012",color: "white", fontSize: "13px"}}
            expires={150}> Informujemy, iż w celu optymalizacji treści dostępnych w naszym serwisie, dostosowania ich do
            Państwa indywidualnych potrzeb korzystamy z informacji zapisanych za pomocą plików cookies na urządzeniach
            końcowych użytkowników. Pliki cookies użytkownik może kontrolować za pomocą ustawień swojej przeglądarki
            internetowej. Dalsze korzystanie z naszego serwisu internetowego, bez zmiany ustawień przeglądarki
            internetowej oznacza, iż użytkownik akceptuje stosowanie plików cookies.</CookieConsent>

    </Box>
);

export default Home;
