import React from 'react';
import { SimpleGrid, Flex, Box, Container, Text } from '@chakra-ui/react';

const ContactInfo = () => {
  const data = [
    { name: 'Nazwa firmy', value: 'CNF PREMIUM Sp. z o.o.' },
    { name: 'Adres', value: 'ul. Osowski zakątek 21, Gdańsk 80-299 ' },
    {
      name: 'Telefon',
      value: '+48691353886',
      link: 'tel:+48691353886',
    },
    {
      name: 'Adres email',
      value: 'kontakt@zapytajmechanika.pl',
      link: 'mailto:kontakt@zapytajmechanika.pl',
    },
  ];

  return (
    <Container maxW={'7xl'} mt="50px" mb="50px">
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={0}>
        <Flex
          direction="column"
          alignItems="start"
          justifyContent="center"
          px={{ base: 8, md: 8, lg: 20 }}
          py={24}
          zIndex={3}
        >
          <Text
            mt={2}
            fontSize={{ base: '3xl', sm: '4xl' }}
            lineHeight="10"
            fontWeight="extrabold"
            letterSpacing="tight"
            color={'gray.900'}
            py={4}
          >
            Skontaktuj się z nami!
          </Text>
          <Text color={'gray.600'}>
            Jeśli chciałbyś z nami współpracować lub masz pytania dotyczące
            projektu, to jest właściwe miejsce, aby je zadać.
          </Text>
        </Flex>
        <Box
          maxW="lg"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          mt="80px"
          mb="50px"
        >
          <Flex flexDir="column" p="6">
            <Text color={'gray.600'} fontSize="sm" mb={4}>
              Podmiot odpowiedzialny za zapytajmechanika.pl
            </Text>
            {data.map(item => (
              <Text color={'gray.600'} py={2}>
                <Text fontWeight="bold" display="inline" mr={2}>
                  {item.name}:
                </Text>
                {item.link ? (
                  <a href={item.link}>
                    <Text _hover={{ textDecor: 'underline' }} display="inline">
                      {item.value}
                    </Text>
                  </a>
                ) : (
                  item.value
                )}
              </Text>
            ))}
          </Flex>
        </Box>
      </SimpleGrid>
    </Container>
  );
};

export default ContactInfo;
