import React from 'react';
import {
  SimpleGrid,
  Flex,
  Image,
  Box,
  Icon,
  Container,
  Text,
  Button,
} from '@chakra-ui/react';

import { FiExternalLink } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import AboutUsImage from '../assets/about_us_1.png';

const CTA = () => (
  <Container maxW={'7xl'} mt="50px" mb="50px">
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={0}>
      <Flex bg="brand.400" alignItems="center">
        <Image
          src={AboutUsImage}
          alt="about us image"
          fit="cover"
          w="full"
          h="lg"
          bg="brand.100"
          loading="lazy"
          opacity={1}
          rounded="2xl"
        />
      </Flex>
      <Flex
        direction="column"
        alignItems="start"
        justifyContent="center"
        px={{ base: 8, md: 8, lg: 20 }}
        py={24}
        zIndex={3}
      >
        <Text
          color={'brand.300'}
          fontSize="lg"
          textTransform="uppercase"
          fontWeight="normal"
          letterSpacing="3px"
        >
          Kilka słów o projekcie
        </Text>
        <Text
          mt={2}
          fontSize={{ base: '3xl', sm: '4xl' }}
          lineHeight="10"
          fontWeight="extrabold"
          letterSpacing="tight"
          color={'gray.900'}
          py={4}
        >
          Jesteśmy tutaj by Ci pomóc
        </Text>
        <Text color={'gray.600'}>
          Jesteśmy grupą specjalistów, pasjonatów i przede wszystkim ludźmi,
          którzy cenią jakość i bezpieczeństwo. Mamy lata doświadczeń w branży
          motoryzacyjnej oraz wzajemnie weryfikujemy swoją wiedzę podnosząc tym
          samym poprzeczkę. Chcemy pomóc wam we wstępnej diagnozie problemu oraz
          wskazać drogę, która mamy nadzieję pomoże rozwiązać wasze problemy z
          autem. Za darmo.
        </Text>
        <Box display="inline-flex" rounded="md" shadow="md" mt={10}>
          <NavLink to="/about">
            <Button
              size={'md'}
              fontWeight={'normal'}
              px={6}
              colorScheme={'red'}
              bg={'#a00012'}
              _hover={{ bg: 'red.800' }}
            >
              Więcej informacji o nas
              <Icon as={FiExternalLink} ml={2} />
            </Button>
          </NavLink>
        </Box>
      </Flex>
    </SimpleGrid>
  </Container>
);

export default CTA;
