import { Box, Badge } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

function Card({ id, title, description, brand, createdAt }) {
  return (
    <Link exact to={`/q/${brand}/${id}`}>
      <Box
        maxW="100%"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="lg"
        shadow="sm"
        overflow="hidden"
        mb={4}
      >
        <Box p="6">
          <Box d="flex" alignItems="baseline">
            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
            >
              {format(createdAt.toDate(), 'dd-MM-yyyy HH:mm')}
            </Box>
          </Box>

          <Box
            mt="1"
            fontWeight="semibold"
            fontSize="md"
            lineHeight="tight"
            isTruncated
            py={1}
          >
            {title}
          </Box>

          <Box
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            maxW="90%"
            pb={3}
          >
            {description}
          </Box>

          <Box d="flex" mt="2" alignItems="center">
            <Badge borderRadius="full" px="2" colorScheme="red">
              {brand}
            </Badge>
          </Box>
        </Box>
      </Box>
    </Link>
  );
}

export default Card;
