import { ArrowDownIcon } from '@chakra-ui/icons';
import {
  Stack,
  Box,
  Container,
  Heading,
  Text,
  Button,
  Icon,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import homeIMG from '../assets/home.png'

export default function Hero() {
  return (
    <Box
      bgImage={`linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.9)) , url(${homeIMG})`}
      w={'100vw'}
      bgSize="cover"
      bgPos="center"
    >
      <Container
        maxW={'5xl'}
        p={6}
        textAlign={['center', 'inherit', 'inherit']}
      >
        <Stack
          align={'center'}
          spacing={[8, 8, 10]}
          py={{ base: 20, md: 28 }}
          direction={{ base: 'column', md: 'row' }}
          p={8}
        >
          <Stack
            flex={1}
            spacing={[5, 10, 10]}
            maxW={['90%', 'inherit', 'inherit']}
          >
            <Heading
              mt={['30px', '10px', '10px']}
              lineHeight={1.1}
              fontWeight={600}
              fontSize={['4xl', '5xl', '7xl']}
              textAlign={['center', 'inherit', 'inherit']}
            >
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: '20%',
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: '#a00012',
                  zIndex: -1,
                }}
                color="white"
              >
                Zadaj pytanie mechanikowi,
              </Text>
              <br />
              <Text as={'span'} color={'#a00012'}>
                zupełnie za darmo!
              </Text>
            </Heading>
            {/* <Text color={'gray.100'}>
              Snippy is a rich coding snippets app that lets you create your own
              code snippets, categorize them, and even sync them in the cloud so
              you can use them anywhere. All that is free!
            </Text> */}
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Link to="/ask">
                <Button
                  size={'lg'}
                  fontWeight="500"
                  fontSize={['18', '26', '26']}
                  px={[6, 12, 12]}
                  py={[4, 7, 7]}
                  colorScheme={'red'}
                  bg={'#a00012'}
                  _hover={{ bg: 'red.800' }}
                >
                  ZAPYTAJ MECHANIKA
                </Button>
              </Link>
              <ScrollLink to="jaktodziala" offset={-150} smooth spy delay={0}>
                <Button
                  size={'md'}
                  fontWeight={'normal'}
                  px={[4, 4, 4]}
                  mt={[2, 2, 2]}
                  leftIcon={<ArrowDownIcon h={4} w={4} color={'gray.600'} />}
                >
                  Zobacz jak to działa
                </Button>
              </ScrollLink>
            </Stack>
          </Stack>
          {/* <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}
        >
          <Blob
            w={'150%'}
            h={'150%'}
            position={'absolute'}
            top={'-20%'}
            left={0}
            zIndex={-1}
            color={useColorModeValue('red.50', 'red.400')}
          />
          <Box
            position={'relative'}
            height={'300px'}
            rounded={'2xl'}
            boxShadow={'2xl'}
            width={'full'}
            overflow={'hidden'}
          >
            <IconButton
              aria-label={'Search Button'}
              variant={'ghost'}
              _focus="false"
              _hover={{ bg: 'transparent' }}
              icon={<Search2Icon w={12} h={12} />}
              size={'lg'}
              backgroundBlendMode="color-burn"
              color={'white'}
              position={'absolute'}
              left={'50%'}
              top={'50%'}
              transform={'translateX(-50%) translateY(-50%)'}
            />
            <Image
              alt={'Hero Image'}
              fit={'cover'}
              align={'center'}
              w={'100%'}
              h={'100%'}
              src={
                'https://images.pexels.com/photos/4489737/pexels-photo-4489737.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
              }
            />
          </Box>
        </Flex> */}
        </Stack>
      </Container>
    </Box>
  );
}

export const Blob = props => (
  <Icon
    width={'100%'}
    viewBox="0 0 578 440"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
      fill="currentColor"
    />
  </Icon>
);
