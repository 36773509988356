import {
  Box,
  chakra,
  Container,
  Stack,
  Link,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';

const SocialButton = ({ children, label, href }) => (
  <chakra.button
    bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
    rounded={'full'}
    w={8}
    h={8}
    cursor={'pointer'}
    as={'a'}
    href={href}
    display={'inline-flex'}
    alignItems={'center'}
    justifyContent={'center'}
    transition={'background 0.3s ease'}
    _hover={{
      bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
    }}
  >
    <VisuallyHidden>{label}</VisuallyHidden>
    {children}
  </chakra.button>
);

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      fontSize="sm"
      textAlign={['center', 'inherit', 'inherit']}
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Text maxW={['50%', 'inherit', 'inherit']}>
          © 2021 Zapytaj Mechanika. Wszelkie prawa zastrzeżone.
        </Text>
        <Stack direction={['column', 'row', 'row']} spacing={6}>
          <Link href="/about">O nas</Link>
          <Link href="/contact">Kontakt</Link>
          <Link href="/regulamin.pdf">Regulamin</Link>
          <Link href="/polityka_prywatnosci.pdf">Polityka prywatności</Link>
          <Link href="/RODO.pdf">RODO</Link>
        </Stack>
        <Stack direction={['row', 'row', 'row']} spacing={6}>
          <SocialButton label={'TikTok'} href={'https://www.tiktok.com/@zapytajmechanika'}>
            <SiTiktok />
          </SocialButton>
          <SocialButton label={'Facebook'} href={'https://www.facebook.com/zapytajmechanika'}>
            <FaFacebook />
          </SocialButton>
          <SocialButton label={'Instagram'} href={'https://www.instagram.com/zapytaj_mechanika'}>
            <FaInstagram />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
}
