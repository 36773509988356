import React from 'react';
import { Button, Flex, Container, Heading, Text, Icon } from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillHome } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';

const Success = () => (
  <Flex minH="calc(100vh - 160px)">
    <Container maxW={'5xl'}>
      <Flex
        h="70vh"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
      >
        <Heading p={2}>Dziękujemy</Heading>
        <Flex>
          <Flex alignItems="center">
            <Icon as={AiFillCheckCircle} h={8} w={8} color="green.500" />
            <Text p={4}>
              Twoje zgłoszenie zostało przyjęte, czekaj na maila z odpowiedzią
              od zapytajmechanika.pl
            </Text>
          </Flex>
        </Flex>
        <NavLink to="/">
          <Button
            mt={4}
            shadow="md"
            size={'md'}
            fontWeight={'normal'}
            px={6}
            colorScheme={'red'}
            bg={'#a00012'}
            _hover={{ bg: 'red.800' }}
          >
            <Icon as={AiFillHome} mr={2} />
            Powrót do strony głównej
          </Button>
        </NavLink>
      </Flex>
    </Container>
  </Flex>
);

export default Success;
