import React from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import {
  Box,
  Image,
  Icon,
  Text,
  Popover,
  Spacer,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  useMediaQuery,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

function HelpModal({ title, description, img, asLink }) {
  const [isMobile] = useMediaQuery('(max-width: 600px)');
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      {!isMobile ? (
        <Popover placement="right-start">
          <PopoverTrigger>
            {!asLink ? (
              <Box>
                <Icon
                  as={BiHelpCircle}
                  w={10}
                  h={10}
                  p={2}
                  ml={2}
                  color="gray.400"
                  cursor="pointer"
                />
              </Box>
            ) : (
              <Text
                fontSize="sm"
                p={2}
                color="gray.500"
                maxW="350px"
                textDecor="underline"
                _hover={{ textDecor: 'underline', cursor: 'pointer' }}
              >
                {title}
              </Text>
            )}
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader fontWeight="semibold">{title}</PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody p={4}>
              <p>{description}</p>
              {img && (
                <Image
                  mt={4}
                  src={
                    img ||
                    'https://kalkulator.kalkulator-oc-ac.auto.pl/kalkulator/common_public/assets/images/infotip-images/rodzaj-pojazdu-infotip.png'
                  }
                />
              )}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        <>
          <Box onClick={onOpen}>
            <Icon
              as={BiHelpCircle}
              w={10}
              h={10}
              p={2}
              ml={2}
              color="gray.400"
              cursor="pointer"
            />
          </Box>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {description}
                <Image
                  mt={4}
                  src={
                    img ||
                    'https://kalkulator.kalkulator-oc-ac.auto.pl/kalkulator/common_public/assets/images/infotip-images/rodzaj-pojazdu-infotip.png'
                  }
                />
              </ModalBody>

              <ModalFooter>
                <Spacer />
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}

export default HelpModal;
