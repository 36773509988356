import React from 'react';
import { Box, Flex, Container } from '@chakra-ui/react';
import ContactInfo from '../components/Contactinfo';

const Contact = () => (
  <Flex minH="calc(100vh - 160px)">
    <Container maxW={'5xl'}>
      <Box>
        <ContactInfo />
      </Box>
    </Container>
  </Flex>
);

export default Contact;
