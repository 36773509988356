import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Flex,
  Image,
  Box,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { AuthContext } from '../config/context';
import firebase from '../config/firebase';
import logo from '../assets/logo2.png';

export default function Navbar() {
  const { user } = useContext(AuthContext);
  const toast = useToast();

  return (
    <Flex
      width="full"
      bg="black"
      justifyContent={
        user ? ['left', 'right', 'right'] : ['center', 'right', 'right']
      }
      pr={[0, 48, 48]}
      zIndex="100"
      position="fixed"
      top="0"
    >
      <a href="/">
        <Box p={4} color="white">
          <Image src={logo} height="60px" alt="logo image" />
        </Box>
      </a>
      <Box>
        {user && (
          <Box pos="absolute" right={user ? 4 : 5} top={7}>
            <Menu>
              <MenuButton
                as={Button}
                variant="outline"
                color="white"
                rightIcon={<ChevronDownIcon />}
                _hover={{ bg: '#ebedf0', color: 'black' }}
                _active={{ bg: '#ebedf0', color: 'black' }}
              >
                Zalogowany
              </MenuButton>
              <MenuList zIndex="999">
                <Link exact to={`/q`}>
                  <MenuItem>Lista wszystkich pytań</MenuItem>
                </Link>
                <MenuItem
                  onClick={() => {
                    firebase
                      .auth()
                      .signOut()
                      .then(() => {
                        // Sign-out successful.
                        toast({
                          title: `Pomyślnie wylogowano`,
                          status: 'success',
                          position: 'top',
                          isClosable: true,
                        });
                      })
                      .catch(error => {
                        toast({
                          title: `Wystąpił błąd podczas wylogowania: ${error.message}`,
                          status: 'error',
                          position: 'top',
                          isClosable: true,
                        });
                      });
                  }}
                >
                  Wyloguj się
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
      </Box>
      <Box
        width="full"
        bg="#c0c2c9"
        zIndex="50"
        position="fixed"
        top="90"
        left="0"
        textAlign="center"
        p={2}
        letterSpacing="1px"
      >
        <a href="https://poradymechanika.pl">
          <Text color="black" fontSize="md">
            Sprawdź koniecznie nasze artykuły i poradniki na{' '}
            <span style={{ textDecoration: 'underline' }}>
              poradymechanika.pl
            </span>
          </Text>
        </a>
      </Box>
    </Flex>
  );
}
